<template>
  <div class="mx-auto w-full max-w-sm">
    <div class="mb-12">
      <img class="h-8 w-auto" src="@/master/assets/autralis/logo-rgb-autralis.png" alt="Workflow">
      <h2 class="mt-6 text-xl leading-9 font-bold text-gray-600">
        <translate>Select your company</translate>
      </h2>
    </div>
    <div v-for="company in $store.getters['master/companies']" :key="'company.' + company.id" class="mt-2">
              <span class="block w-full rounded shadow-sm">
                <button @click="select(company)"
                        class="select-company w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
                  {{ company.name }}
                </button>
              </span>
    </div>
    <div class="mt-4 w-full flex flex-col items-center">
      <a @click="logOut"
         class="font-medium text-gray-600 hover:text-gray-500 focus:outline-none focus:underline transition ease-in-out duration-150 cursor-pointer">
        <translate>Log out</translate>
      </a>
    </div>
    <div class="mt-8">
      <Languages></Languages>
    </div>
  </div>
</template>


<script>
import router from "@/router";
import Languages from "@/master/components/Languages";

export default {
  name: "LoginCompany",
  components: {Languages},
  data: function () {
    return {}
  },
  methods: {
    select: function (company) {
      this.$store.commit('master/setCompany', company);
      router.push('login-unit');
    },
    logOut: function () {
      this.$store.commit('master/logOut');
      router.push('login');
    }
  }
}
</script>

<style scoped>

</style>